import { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { toast } from "react-toastify";

// Material-UI components
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";

// Context and services
import { AuthContext } from "context";
import { LoginUserAPI } from "services/user.service";
import { setItemToStorage, getItemFromStorage, logout } from "utils/Constant";

// Images
import logo from "assets/images/logo.png";
import { createGlobalStyle } from 'styled-components';


const GlobalStyle = createGlobalStyle`
  @keyframes onAutoFillStart {
    from {}
    to {}
  }

  @keyframes onAutoFillCancel {
    from {}
    to {}
  }

  input:-webkit-autofill {
    animation-name: onAutoFillStart;
  }

  input:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
  }
`;

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  padding: theme.spacing(3),
  width: '100%',
  maxWidth: '400px',
  position: 'fixed', // Changed to 'fixed' to ensure it stays centered regardless of scrolling
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const CircularLogo = styled('div')({
  width: '110px',
  height: '110px',
  borderRadius: '50%',
  overflow: 'hidden',
  margin: '0 auto 20px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: "10px",
});


const StyledMDInput = styled(MDInput)(({ theme }) => ({
  '& .MuiInputBase-root': {
    '&:-webkit-autofill': {
      transition: 'background-color 5000s ease-in-out 0s',
      WebkitTextFillColor: theme.palette.text.primary,
    },
  },
  '& .MuiInputLabel-root': {
    transition: theme.transitions.create(['top', 'font-size'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  '& .MuiInputBase-root:-webkit-autofill ~ .MuiInputLabel-root': {
    transform: 'translate(14px, -9px) scale(0.75)',
  },
}));

function AutofillAwareInput(props) {
  const inputRef = useRef(null);

  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      const checkAutofill = () => {
        if (input.value !== '') {
          input.classList.add('autofilled');
        } else {
          input.classList.remove('autofilled');
        }
      };

      // Check immediately
      checkAutofill();

      // Check on input events
      input.addEventListener('input', checkAutofill);

      // Check periodically for a short time after component mount
      const intervalId = setInterval(checkAutofill, 100);
      setTimeout(() => clearInterval(intervalId), 2000);

      return () => {
        input.removeEventListener('input', checkAutofill);
        clearInterval(intervalId);
      };
    }
  }, []);

  return <StyledMDInput {...props} inputRef={inputRef} />;
}

const MovingBackground = styled('div')({
  position: 'fixed', // Changed from 'absolute' to 'fixed'
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
  zIndex: -1,
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '200%', // Increased to ensure full coverage during animation
    height: '200%',
    top: '-50%',
    left: '-50%',
    background: 'linear-gradient(45deg, #e0f7fa 0%, #eceff1 99%, #eceff1 100%)', // Light blue to greyish white
    animation: 'moveBackground 15s ease infinite',
    backgroundSize: '300% 300%',
  },
  '@keyframes moveBackground': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' },
  },
});

function Login() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const cookies = getItemFromStorage("remember", true) ? getItemFromStorage("remember", true) : { email: "", password: "" };

  const myInfo = {
    email: cookies.email,
    password: cookies.password,
    rememberMe: cookies.email ? true : false
  };

  const formik = useFormik({
    initialValues: {
      email: myInfo.email,
      password: myInfo.password,
      rememberMe: myInfo.rememberMe
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Email is required"),
      password: Yup.string().required("Password is required"),
      rememberMe: Yup.boolean(),
    }),
    onSubmit: handleSubmit
  });

  useEffect(() => {
    const data = getItemFromStorage("data");
    if (data?._id || data?.accessToken) {
      navigate("/Dashboard");
    }
  }, [navigate]);

  async function handleSubmit(values) {
    setIsLoading(true);
    try {
      const res = await LoginUserAPI(values);
      if (res.success) {
        if (values.rememberMe) {
          setItemToStorage('remember', JSON.stringify({
            email: values.email,
            password: values.password
          }))
        } else {
          logout()
        }

        toast.success(res.msg);
        setItemToStorage('data', res.data);
        setItemToStorage('accessToken', res.data.accessToken);
        authContext.login(res.data, res.data.accessToken);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.message || error.msg || error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <><GlobalStyle />
    <BasicLayoutLanding>
      <MovingBackground />
      <StyledCard>
        <CircularLogo>
          <img
            src={logo}
            alt="Company Logo"
            style={{
              width: '150%', // Adjusted to fill the circular container
              height: '150%', // Adjusted to fill the circular container
              objectFit: 'cover', // Changed from 'contain' to 'cover' to ensure it fills the entire area
              transform: 'translateY(5%)' // Moves the logo down by 10% of its height
            }} />
        </CircularLogo>
        <MDTypography variant="h4" fontWeight="medium" color="info" textAlign="center" mb={4}>
          Welcome Back
        </MDTypography>
        <form noValidate onSubmit={formik.handleSubmit}>
          <MDBox mb={3}>
            <AutofillAwareInput
              type="email"
              label="Email"
              fullWidth
              {...formik.getFieldProps('email')}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email} />
          </MDBox>
          <MDBox mb={3}>
            <AutofillAwareInput
              type="password"
              label="Password"
              fullWidth
              {...formik.getFieldProps('password')}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password} />
          </MDBox>
          <MDBox display="flex" alignItems="center" mb={3}>
            <Switch
              checked={formik.values.rememberMe}
              onChange={formik.handleChange}
              name="rememberMe"
              color="info" />
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              onClick={() => formik.setFieldValue("rememberMe", !formik.values.rememberMe)}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              Remember me
            </MDTypography>
          </MDBox>
          <MDBox mt={4}>
            <MDButton
              variant="contained"
              color="info"
              fullWidth
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Signing in..." : "SIGN IN"}
            </MDButton>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              {" "}
              <MDTypography
                component={Link}
                to="/auth/forgot-password"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Forgot your password?
              </MDTypography>
            </MDTypography>
          </MDBox>
        </form>
      </StyledCard>
    </BasicLayoutLanding></>
    
  );
}

export default Login;