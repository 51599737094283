import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useMaterialUIController } from "context";
import { toast } from "react-toastify";
import axios from 'axios';

const Input = styled('input')({
  display: 'none',
});

const MAX_FILE_SIZE = 15 * 1024 * 1024; // 15MB
const ALLOWED_FILE_TYPES = ['audio/mpeg', 'audio/mp4', 'audio/x-m4a'];

function VoiceCloning() {
  const [controller] = useMaterialUIController();
  const { darkMode, sidenavColor } = controller;

  const [cloneName, setCloneName] = useState('');
  const [audioFiles, setAudioFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cloneResult, setCloneResult] = useState(null);

  const handleCloneNameChange = (event) => {
    setCloneName(event.target.value);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter(file => {
      if (file.size > MAX_FILE_SIZE) {
        toast.warn(`File ${file.name} exceeds the 15MB size limit and was skipped.`);
        return false;
      }
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        toast.warn(`File ${file.name} is not a supported audio format and was skipped.`);
        return false;
      }
      return true;
    });
    setAudioFiles(validFiles);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!cloneName || audioFiles.length === 0) {
      toast.error("Please provide a name for your cloned voice and at least one audio file");
      return;
    }

    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append('name', cloneName);
      audioFiles.forEach((file) => {
        formData.append('audio_samples', file);
      });

      const apiUrl = process.env.REACT_APP_API_URL
        ? `${process.env.REACT_APP_API_URL}clone-voices`
        : 'http://localhost:5000/api/clone-voices';

      console.log('Sending request to clone voice API:', apiUrl);

      const response = await axios.post(apiUrl, formData, {
        withCredentials: true, // Include cookies if needed
        headers: {
          'Accept': 'application/json',
        },
      });

      console.log('Received response from clone voice API');
      const result = response.data;
      console.log('Parsed response:', result);
      if (result.success) {
        setCloneResult(result.data);
        toast.success("Voice cloned successfully!");
      } else {
        throw new Error(result.msg || 'An error occurred while cloning the voice');
      }
    } catch (error) {
      console.error('Error cloning voice:', error);
      toast.error(`Error cloning voice: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor={sidenavColor}
            borderRadius="lg"
            coloredShadow={sidenavColor}
          >
<Typography 
  variant="h6" 
  style={{ color: "white" }} // Inline style to override any conflicts
>
  Voice Cloning
</Typography>

          </MDBox>
          <CardContent>
          <Box mb={3}>
  <Typography variant="h6" color={darkMode ? "white" : "text.primary"} gutterBottom>
    Guidelines
  </Typography>
  <List sx={{ listStyleType: 'disc', pl: 3 }}>
    {[
      "Audio files with a duration of about 5 minutes work best (min. 5 minutes, max 1 hour)",
      "Recordings from a quiet environment will have the least artifacts and best quality.",
      "Speech patterns such as pauses, laughter, tone and inflection will transfer as well.",
      "Can upload multiple files. Each file size limit: 15MB",
      "Format supported: .mp3 / .m4a / .mp4"
    ].map((guideline, index) => (
      <ListItem
        key={index}
        sx={{ display: 'list-item', paddingLeft: 2, listStylePosition: 'outside', alignItems: 'flex-start' }}
      >
        <ListItemText
          primary={guideline}
          primaryTypographyProps={{
            variant: 'subtitle2', // Slightly smaller than 'body2'
            color: darkMode ? "white" : "text.primary", // Matches the dark mode logic for Guidelines
            lineHeight: 1.6
          }}
        />
      </ListItem>
    ))}
  </List>
</Box>


            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Cloned Voice Name"
                variant="outlined"
                value={cloneName}
                onChange={handleCloneNameChange}
                margin="normal"
                required
              />
              <Box my={2}>
                <Input
                  accept="audio/mpeg,audio/mp4,audio/x-m4a"
                  id="audio-file-input"
                  multiple
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="audio-file-input">
                <MDButton
  variant="contained"
  color={sidenavColor}
  component="span"
  fullWidth={false} // If you don't want it to be full width like your submit button
>
  Upload Audio Files
</MDButton>
                </label>
                <Typography variant="body2" color={darkMode ? "white" : "text.secondary"} mt={1}>
                  {audioFiles.length > 0 ? `${audioFiles.length} file(s) selected` : 'No file chosen'}
                </Typography>
              </Box>
              <MDButton
                type="submit"
                variant="gradient"
                color={sidenavColor}
                fullWidth
                disabled={isLoading}
              >
                {isLoading ? (
                  <Box display="flex" alignItems="center">
                    <CircularProgress size={24} color="inherit" />
                    <Box ml={1}>Cloning...</Box>
                  </Box>
                ) : (
                  "Clone Voice"
                )}
              </MDButton>
            </form>
            {cloneResult && (
              <Box mt={2}>

                <Typography variant="body2" color={darkMode ? "white" : "text.secondary"}>
                   Email eze@autocall.com.au your Clone Name: {cloneName} and Clone ID: {cloneResult.voice_id} and let him know which phone you want to have this voice.
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default VoiceCloning;