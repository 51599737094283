import { useState, useEffect } from "react";
// Auto Call React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Auto Call React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/user-profile/Header";

// import AuthService from "../../services/auth-service";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { toast } from "react-toastify";
import { getItemFromStorage } from "utils/Constant";
import { UpdateUserAPI } from "services/user.service";
import CustomInput from "components/ReusableComponents/CustomInput";
import { GetMyProfileAPI } from "services/user.service";

const UserProfile = () => {

  const myInfo = getItemFromStorage("data", false)

  const formik = useFormik({
    initialValues: {
      name: myInfo.name,
      email: myInfo.email,
      password: "",
      subscribedPackage: myInfo.subscribedPackage,
      role: myInfo.role
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required Field"),
      email: Yup.string().required("Required Field").email('Invalid Email Address'),
      password: Yup.string(),
      subscribedPackage: Yup.string(),
      role: Yup.string(),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    }
  })


  const handleSubmit = async (values) => {
    try {
      const res = await UpdateUserAPI(values, myInfo._id);
      if (res.success) {
        toast.success(res.msg);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.message || error.msg || error)
    }
  }



  const getUserData = async () => {
    try {
      const res = await GetMyProfileAPI();
      if (res.success) {
        formik.setFieldValue("name", res.data.name)
        formik.setFieldValue("email", res.data.email)
        formik.setFieldValue("subscribedPackage", res.data.subscribedPackage)
        formik.setFieldValue("role", res.data.role)
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.message || error.msg || error)
    }
  };

  useEffect(() => {
    getUserData();
  }, []);




  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header name={formik.values.name} role={formik.values.role}>
        <MDBox
          component="form"
          role="form"
          onSubmit={formik.handleSubmit}
          display="flex"
          flexDirection="column"
        >
          <MDBox
            className="grid grid-cols-12 gap-6"
            mt={5} mb={3}>

            <CustomInput
              required={true}
              formik={formik}
              name="name"
              className='col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6 '
              label="Name"
              placeholder="Enter Your Name Here..."
            />

            <CustomInput
              required={true}
              formik={formik}
              name="email"
              className='col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6 '
              label="Email"
              placeholder="Enter Your Email Here..."
            />

            <CustomInput
              required={false}
              formik={formik}
              name="password"
              className='col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6 '
              label="Password"
              placeholder="Enter Your Password If You Want To Update It..."
            />
            <div className="col-span-12"></div>
            <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6 flex justify-end">
              <MDButton variant="gradient" color="info" type="submit">
                Save changes
              </MDButton>
            </div>
          </MDBox>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
};

export default UserProfile;
