import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { DeleteAudioAPI } from 'services/audio.service';
import { AddAudiosAPI } from 'services/audio.service';
import { GetAllAudiosAPI } from 'services/audio.service';
import { Card, Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import CustomButton from 'components/ReusableComponents/CustomButton'
import Footer from 'examples/Footer'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import CustomModal from 'components/ReusableComponents/CustomModal';
import AudioManagementForm from './AudioManagementForm';

const AudioManagement = () => {
    const backendBaseUrl = process.env.REACT_APP_API_URL;

    const [addModal, setAddModal] = useState(false)
    const [viewModal, setViewModal] = useState(false)
    const [data, setData] = useState([]);
    const [rowData, setRowData] = useState({});


    useEffect(() => {
        getAllAudios()
    }, [])

    const handleAdd = () => {
        setRowData({
            title: '',
            audio: {},
            buttonText: "",
            audioFor: "",
            pathwayId: "",
            voice: "",
            waitingForGreeting: true,
            blockInterruptions: true,
            interruptionThreshold: 0,
            model: "",
            keywords: [],
            pronunciationGuide: [],
            language: "",
            timezone: "",
            voiceMailMessage: "",
            voiceMailAction: "",
            maxDuration: 0,
            record: true,
            from: "",
            webhook: "",
            analysisSchema: [],
            voiceMailSms: "",
            endpoint: "",
            encryptedKey: "",
        })
        setAddModal(true)
    }

    const getAllAudios = async () => {
        try {
            const res = await GetAllAudiosAPI()
            if (res.success) {
                setData(res.data)
            } else {
                throw new Error(res.msg)
            }
        } catch (error) {
            toast.error(error?.msg || error?.message || error)
        }
    }

    const addAudio = async (data) => {
        try {
            let formData = new FormData();
            formData.append("audio", data.audio);
            formData.append("title", data.title);
            formData.append("buttonText", data.buttonText);
            formData.append("audioFor", data.audioFor);
            formData.append("pathwayId", data.pathwayId);
            formData.append("voice", data.voice);
            formData.append("waitingForGreeting", data.waitingForGreeting);
            formData.append("blockInterruptions", data.blockInterruptions);
            formData.append("interruptionThreshold", data.interruptionThreshold);
            formData.append("model", data.model);
            formData.append("keywords", JSON.stringify(data.keywords));
            formData.append("pronunciationGuide", JSON.stringify(data.pronunciationGuide));
            formData.append("language", data.language);
            formData.append("timezone", data.timezone);
            formData.append("voiceMailMessage", data.voiceMailMessage);
            formData.append("voiceMailAction", data.voiceMailAction);
            formData.append("maxDuration", data.maxDuration);
            formData.append("record", data.record);
            formData.append("from", data.from);
            formData.append("webhook", data.webhook);
            formData.append("voiceMailSms", data.voiceMailSms);
            formData.append("endpoint", data.endpoint);
            formData.append("encryptedKey", data.encryptedKey);

            const schema = Object.fromEntries(data.analysisSchema.map(item => [item.key.trim(), item.value.trim()]));
            formData.append("analysisSchema", JSON.stringify(schema));

            const res = await AddAudiosAPI(formData)
            if (res.success) {
                toast.success(res.msg)
                getAllAudios()
            } else {
                throw new Error(res.msg)
            }
            closeModal()
        } catch (error) {
            toast.error(error?.msg || error?.message || error)
            closeModal()
        }
    }

    const handleView = (payLoad) => {
        settingData(payLoad)
        setViewModal(true);
    }

    const closeModal = () => {
        setViewModal(false)
        setAddModal(false)
    }

    const handleDelete = async (payLoad) => {
        try {
            const res = await DeleteAudioAPI(payLoad._id, payLoad.audio)
            if (res.success) {
                toast.success(res.msg)
                getAllAudios()
            } else {
                throw new Error(res.msg)
            }
        } catch (error) {
            toast.error(error?.msg || error?.message || error)
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={3} mb={3} className="flex justify-end">
                <CustomButton
                    size="small"
                    color="info"
                    className="mt-4 mb-4"
                    onClick={() => handleAdd()}
                >
                    Add Audio
                </CustomButton>
            </MDBox>
            <MDBox mb={3}>
                {data.length == 0 && <MDBox className="flex justify-center mt-10 mb-10">
                    <MDTypography variant="h2">No Data Found</MDTypography>
                </MDBox>
                }
                <Grid container spacing={3} justifyContent="around">
                    {data.map(item => (
                        <Grid key={item._id} item xs={12} lg={4}>
                            <Card className={`h-100`}>
                                <MDBox p={2}>
                                    <MDTypography variant="h6">{item.title}</MDTypography>
                                    <MDTypography className="pt-4" variant="h6">Audio Will Show To : {item.audioFor.name} ({item.audioFor.email})</MDTypography>
                                    <div className='scroll-container mt-4 flex justify-center'>
                                        <audio controls>
                                            <source src={backendBaseUrl + item.audio} type="audio/wav" />
                                        </audio>

                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <CustomButton
                                            size="small"
                                            color="error"
                                            onClick={() => handleDelete(item)}
                                        >
                                            Delete
                                        </CustomButton>
                                    </div>

                                </MDBox>
                            </Card>
                        </Grid>
                    ))}

                </Grid>
            </MDBox>

            <Footer />

            {addModal && <CustomModal size="xl" title={"Add Audio"} open={addModal} close={closeModal}>
                <AudioManagementForm data={rowData} close={closeModal} handleSubmit={addAudio} disabled={false} />
            </CustomModal>}

        </DashboardLayout>
    )
}

export default AudioManagement