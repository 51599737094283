import React from 'react'
import { useFormik } from "formik";
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import CustomInput from 'components/ReusableComponents/CustomInput';
import CustomButton from 'components/ReusableComponents/CustomButton';


const ConductCallsForm = (props) => {
    const {
        data = null,
        handleSubmit = () => null,
        close = () => null,
        disabled = false
    } = props

    const formik = useFormik({
        initialValues: data,
        validationSchema: Yup.object().shape({
            index: Yup.number(),
            name: Yup.string(),
            phoneNumber: Yup.string().required("Required Field!"),
            position: Yup.string(),
            status: Yup.string().nullable(true),
            callId: Yup.string().nullable(true),
            createdBy: Yup.string(),
            data: Yup.string(),
        }),
        onSubmit: (values) => {
            if (!values.phoneNumber.toString().startsWith('+')) {
                toast.error("Phone number must have country code.");
                return
            }
            handleSubmit(values);
        }
    })


    return (
        <>
            <form onSubmit={formik.handleSubmit} noValidate>
                <div className="grid grid-cols-12 gap-6">

                    <CustomInput
                        disabled={disabled}
                        formik={formik}
                        className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
                        name="name"
                        label="Name"
                        placeholder="Enter Name Here..."
                    />

                    <CustomInput
                        disabled={disabled}
                        required={true}
                        formik={formik}
                        className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
                        name="phoneNumber"
                        label="Phone Number"
                        placeholder="Phone number must starts with country code."
                    />

                    <CustomInput
                        disabled={disabled}
                        formik={formik}
                        className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
                        name="position"
                        label="Position"
                        placeholder="Enter Position Here.."
                    />


                    {!disabled &&
                        <CustomButton
                            color="primary"
                            size='large'
                            className='col-span-12'
                            type='submit'
                        >
                            Save
                        </CustomButton>
                    }
                </div>
            </form>

        </>
    )
}

export default ConductCallsForm