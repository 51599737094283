import { Typography, TypographyVariant } from "@mui/material";

// interface CustomTypographyProps {
//     children: any;
//     variant?: TypographyVariant;
//     // variant: 'body1' | 'body2' | 'button' | 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'inherit' | 'overline' | 'subtitle1' | 'subtitle2' | '';
//     className?: string;
//     sxStyle?: any;
//     noWrap?: boolean;
//     align?: 'center' | 'inherit' | 'justify' | 'left' | 'right'
// }

const CustomTypoGraphy = (props) => {
    const {
        children = "",
        variant = "subtitle1",
        className = "",
        noWrap = true,
        sxStyle = {},
        align = "inherit"
    } = props
    return (
        <>
            <Typography
                sx={sxStyle}
                className={className}
                variant={variant}
                noWrap={noWrap}
                align={align}
                gutterBottom
            >
                {children}
            </Typography>
        </>
    )
};

export default CustomTypoGraphy;
