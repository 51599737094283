import axios from "axios";
import { hideLoaderAPI, showLoaderAPI, getItemFromStorage, logout } from "utils/Constant";


const noLoaderApis = []

const axiosService = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axiosService.interceptors.request.use(
    (config) => {
        if (!noLoaderApis.includes(config.url)) {
            showLoaderAPI();
        }
        // For Authorization
        let token = getItemFromStorage("data")?.accessToken;
        config.headers["authorization"] = "Bearer " + token;

        return config;
    },
    (error) => {
        hideLoaderAPI();
        return Promise.reject(error);
    }
);

axiosService.interceptors.response.use(
    (response) => {
        hideLoaderAPI();
        return response;
    },
    (error) => {
        hideLoaderAPI();
        if (error?.response?.status === 401) {
            logout()
        }

        return Promise.reject(error);
    }
);

export const getApi = async (endpoint) => {
    try {
        const response = await axiosService.get(endpoint);
        return response.data;
    } catch (error) {
        return { success: false, msg: error?.response?.data?.msg || error.message, data: {} };
    }
};

export const postApi = async (endpoint, data) => {
    try {
        const response = await axiosService.post(endpoint, data);
        return response.data;
    } catch (error) {
        return { success: false, msg: error?.response?.data?.msg || error.message, data: {} };
    }
};

export const putApi = async (endpoint, data) => {
    try {
        const response = await axiosService.put(endpoint, data);
        return response.data;
    } catch (error) {
        return { success: false, msg: error?.response?.data?.msg || error.message, data: {} };
    }
};

export const deleteApi = async (endpoint) => {
    try {
        const response = await axiosService.delete(endpoint);
        return response.data;
    } catch (error) {
        return { success: false, msg: error?.response?.data?.msg || error.message, data: {} };
    }
};
