import {
    FormControl,
    FormHelperText,
    TextField,
    Autocomplete,
} from '@mui/material';
import { useCallback } from 'react';

// interface CustomAutoCompleteProps {
//     formik: any;
//     label: string;
//     name: string;
//     options: any[];
//     labelsList: string[];
//     savedLabel: string;
//     disabled?: boolean;
//     required?: boolean;
//     fullWidth?: boolean;
//     className?: string;
//     size?: "small" | "medium";
//     placeholder?: string;
//     disableClearable?: boolean;
// }

const CustomAutoComplete = (props) => {
    const {
        formik,
        name,
        options,
        labelsList,
        label,
        fullWidth = true,
        className = "my-4",
        disabled = false,
        required = false,
        size = "medium",
        placeholder = "",
        savedLabel = "",
        disableClearable = false
    } = props;

    const getOptionLabel = useCallback((option) => {
        if (labelsList[1]) {
            return option[labelsList[0]] + " - " + option[labelsList[1]] || option;
        } else {
            return option[labelsList[0]] || option;
        }
    }, [labelsList]);

    return (
        <FormControl className={`${className} d-flex align-items-center`} fullWidth={fullWidth}>
            <Autocomplete
                size={size}
                disableClearable={disableClearable}
                disabled={disabled}
                id={name}
                options={options}
                getOptionLabel={getOptionLabel}
                autoHighlight
                value={options.find(option => option[savedLabel] === formik.values[name]) || null}
                onChange={(event, selected) => {
                    const selectedValue = selected ? selected[savedLabel] : "";
                    formik.setFieldValue(name, selectedValue);
                }}
                renderInput={(params) => (
                    <TextField {...params} required={required} label={label} placeholder={placeholder} />
                )}
            />
            {formik.touched[name] && formik.errors[name] && (
                <FormHelperText error>
                    {formik.errors[name]}
                </FormHelperText>
            )}
        </FormControl>
    );
}

export default CustomAutoComplete;
