import { deleteApi, getApi, postApi, putApi } from "./Axios"


const base = 'users'

const AddUserAPI = (data) => {
  return postApi(`${base}/add-user`, data)
}

const LoginUserAPI = (data) => {
  return postApi(`${base}/login`, data)
}

const DeleteUserAPI = (id) => {
  return deleteApi(`${base}/remove-user?id=${id}`)
}

const GetUsersAPI = (id = "") => {
  return getApi(`${base}/?id=${id}`)
}

const GetMyProfileAPI = () => {
  return getApi(`${base}/get-me`)
}

const UpdateUserAPI = (data, id) => {
  return putApi(`${base}/update-user?id=${id}`, data)
}



export {
  AddUserAPI,
  LoginUserAPI,
  GetUsersAPI,
  DeleteUserAPI,
  UpdateUserAPI,
  GetMyProfileAPI
}