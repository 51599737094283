import { deleteApi, getApi, postApi, putApi } from "./Axios"


const base = 'conduct-calls'

const AddCallsAPI = (data) => {
  return postApi(`${base}/add-calls`, data)
}

const MakeBlandCallsAPI = (data) => {
  return postApi(`${base}/make-calls-to-bland`, data)
}

const myCallsAPI = () => {
  return getApi(`${base}/get-my-calls`)
}

const GetCallsAPI = () => {
  return getApi(`${base}/get-all-calls`)
}

const getInBoundCallsDetailsAPI = (callId) => {
  return getApi(`${base}/get-call-detail?call_id=${callId}`)
}



export {
  AddCallsAPI,
  GetCallsAPI,
  myCallsAPI,
  getInBoundCallsDetailsAPI,
  MakeBlandCallsAPI
}