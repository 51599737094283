import { deleteApi, getApi, postApi, putApi } from "./Axios"


const base = 'audios'

const AddAudiosAPI = (data) => {
  return postApi(`${base}/add-audio`, data)
}

const GetMyAudiosAPI = () => {
  return getApi(`${base}/get-my-audios`)
}

const GetAllAudiosAPI = () => {
  return getApi(`${base}/get-all-audios`)
}

const DeleteAudioAPI = (id, filePath) => {
  return deleteApi(`${base}/delete-audio?id=${id}&filePath=${filePath}`)
}



export {
  AddAudiosAPI,
  DeleteAudioAPI,
  GetAllAudiosAPI,
  GetMyAudiosAPI
}