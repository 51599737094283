import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material'

// interface CustomCheckBoxProps {
//     disabled?: boolean;
//     className?: string;
//     label: string;
//     name: string;
//     formik: any;
//     key?: any;
//     required?: boolean;
// }
const CustomCheckBox = (props) => {
    const {
        disabled = false,
        className = "",
        label = "",
        name = "",
        key = "",
        formik = null,
        required = false,
    } = props

    return (
        <FormControl
            key={key}
            required={required}
            error={formik.touched[name] && formik.errors[name]}
            component="fieldset"
            className={className}
        >
            <FormGroup >
                <FormControlLabel
                    required={required}
                    label={label}
                    control={<Checkbox
                        disabled={disabled}
                        name={name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values[name]}
                    />}
                />
                {formik.touched[name] && formik.errors[name] && <FormHelperText error>{formik.errors[name]}</FormHelperText>}
            </FormGroup>
        </FormControl>

    )
}

export default CustomCheckBox