import React from 'react'
import { useFormik } from "formik";
import * as Yup from 'yup'
import { toast } from "react-toastify";
import CustomCard from 'components/ReusableComponents/CustomCard';
import CustomAutoComplete from 'components/ReusableComponents/CustomAutoComplete';
import CustomInput from 'components/ReusableComponents/CustomInput';
import CustomButton from 'components/ReusableComponents/CustomButton';

const UserForm = (props) => {
    const {
        data = null,
        handleSubmit = () => null,
        close = () => null,
        disabled = false
    } = props

    const formik = useFormik({
        initialValues: data,
        validationSchema: Yup.object().shape({
            _id: Yup.string(),
            name: Yup.string().required("Required Field"),
            email: Yup.string().required("Required Field"),
            password: Yup.string().required("Required Field"),
            role: Yup.string().required("Required Field"),
            subscribedPackage: Yup.string()
        }),
        onSubmit: (values) => {
            handleSubmit(values);
        }
    })



    return (
        <>
            <form onSubmit={formik.handleSubmit} noValidate>
                <div className="grid grid-cols-12 gap-6">


                    <CustomInput
                        disabled={disabled}
                        required={true}
                        formik={formik}
                        className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
                        name="name"
                        label="Name"
                        placeholder="Enter Your Name Here..."
                    />

                    <CustomInput
                        disabled={disabled}
                        required={true}
                        formik={formik}
                        className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
                        name="email"
                        label="Email"
                        placeholder="Enter Your Email"
                    />

                    {!data._id && <CustomInput
                        disabled={disabled}
                        required={true}
                        formik={formik}
                        className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
                        name="password"
                        label="Password"
                        placeholder="Enter Your Password"
                    />}

                    <CustomAutoComplete
                        disabled={disabled}
                        formik={formik}
                        label="Select User Role"
                        name="role"
                        options={[
                            { key: "Admin", value: "Admin" },
                            { key: "User", value: "User" },
                        ]}
                        labelsList={['key']}
                        savedLabel='value'
                        required={true}
                        className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
                        placeholder="Select Role"
                        disableClearable={true}
                    />

                    <CustomAutoComplete
                        disabled={disabled}
                        formik={formik}
                        label="Select Package Subscribed By User"
                        name="subscribedPackage"
                        options={[
                            { key: "Starter", value: "Starter" },
                            { key: "Business", value: "Business" },
                            { key: "Enterprise", value: "Enterprise" },
                        ]}
                        labelsList={['key']}
                        savedLabel='value'
                        required={true}
                        className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
                        placeholder="Select Package"
                        disableClearable={true}
                    />






                    {!disabled &&
                        <CustomButton
                            color="primary"
                            size='large'
                            className='col-span-12'
                            type='submit'
                        >
                            Save
                        </CustomButton>
                    }
                </div>
            </form>

        </>
    )
}

export default UserForm