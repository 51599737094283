import Button from '@mui/material/Button';
import MDButton from 'components/MDButton';


// interface CustomButtonProps {
//     type?: "button" | "submit" | "reset" | undefined;
//     variant?: 'text' | "contained" | "outlined";
//     disabled?: boolean;
//     className?: string;
//     color?: 'primary' | 'secondary' | 'success' | 'error'
//     size?: 'small' | 'medium' | 'large'
//     onClick?: () => void;
//     children: React.ReactNode;
// }

const CustomButton = (props) => {
    const { type = "button",
        className = "",
        onClick = () => null,
        children = null,
        variant = "contained",
        disabled = false,
        color = "text",
        size = "medium"
    } = props
    return (
        <>
            <MDButton
                type={type}
                className={className}
                onClick={onClick}
                disabled={disabled}
                variant={variant}
                color={color}
                size={size}
            >
                {children}
            </MDButton>
        </>
    )
}

export default CustomButton