import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { RxCross1 } from "react-icons/rx";
import CustomTypoGraphy from './CustomTypography';

// interface CustomModalProps {
//     open: true | false;
//     close: () => boolean | void;
//     children: React.ReactNode;
//     title?: string;
//     backDrop?: boolean;
//     fullScreen?: boolean;
//     size?: "xs" | "sm" | "md" | "lg" | "xl"
// }

const CustomModal = (props) => {
    const {
        open = false,
        close = () => null,
        children = null,
        title = "",
        backDrop = false,
        fullScreen = false,
        size = "md"
    } = props

    const handleClose = (e, reason) => {
        if (reason === "backdropClick" && backDrop) {
            return
        } else {
            close()
        }
    };


    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={size}

                // sx={(data) => { console.log("data: ", data) }}
                className="custom-modal"
            >
                <DialogTitle
                    sx={({ palette: { background } }) => ({
                        background: background.default,
                        m: 0, p: 2
                    })}
                    id="customized-dialog-title">
                    <CustomTypoGraphy variant="h5">{title}</CustomTypoGraphy>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    color='primary'
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'black',
                        // color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <RxCross1 color="black" />
                </IconButton>
                <DialogContent
                    sx={({ palette: { background } }) => ({
                        background: background.default
                    })}
                    className=" p-4 bg-whitesmoke" dividers>
                    {children}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default CustomModal;


