import { deleteApi, getApi, postApi, putApi } from "./Axios"


const base = 'otp'

const SendOtpAPI = (data) => {
  return postApi(`${base}/send-otp`, data)
}

const VerifyOtpAPI = (data) => {
  return postApi(`${base}/verify-otp`, data)
}

const UpdatePasswordAPI = (data) => {
  return postApi(`${base}/update-password`, data)
}


export {
  SendOtpAPI,
  VerifyOtpAPI,
  UpdatePasswordAPI
}