import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Auto Call React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Auto Call React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/user-management/data";
import CustomTable from "components/ReusableComponents/CustomTable";
import { useEffect, useState } from "react";
import { GetUsersAPI } from "services/user.service";
import { toast } from "react-toastify";
import { DeleteUserAPI } from "services/user.service";
import { UpdateUserAPI } from "services/user.service";
import { AddUserAPI } from "services/user.service";
import CustomModal from "components/ReusableComponents/CustomModal";
import UserForm from "./UserForm";

const UserManagement = () => {
  const { columns, rows } = authorsTableData();

  const columnsName = [
    { label: "User ID", dataField: "_id" },
    { label: "Name", dataField: "name" },
    { label: "Email", dataField: "email" },
    { label: "Subscribed Package", dataField: "subscribedPackage" },
    { label: "Role", dataField: "role" },
    { label: "Action", dataField: "action", access: { add: true, view: true, edit: true, delete: true } },
  ]

  const [addModal, setAddModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const [rowData, setRowData] = useState({});
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getAllUsers()
  }, [])

  const getAllUsers = async () => {
    try {

      const res = await GetUsersAPI()
      if (res.success) {
        setTableData(res.data)
      } else {
        toast.error(res.msg)
      }

    } catch (error) {
      toast.error(error.message || error.msg || error)
    }
  }


  const handleAdd = () => {
    settingData({
      _id: '',
      name: '',
      email: '',
      password: '',
      role: "User",
      subscribedPackage: ""
    })
    setAddModal(true)
  }

  const handleView = (data) => {
    settingData({ ...data, password: "data" })
    setViewModal(true);
  }

  const handleEdit = (data) => {
    settingData({ ...data, password: "data" })
    setEditModal(true);
  }

  const handleDelete = async (data) => {
    try {
      const res = await DeleteUserAPI(data._id)
      if (res.success) {
        toast.success(res.msg)
        getAllUsers()
      } else {
        toast.error(res.msg)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const closeModal = () => {
    setAddModal(false)
    setEditModal(false)
    setViewModal(false)
  }

  const handleSubmit = async (payLoad) => {
    try {
      if (payLoad?._id) {
        const res = await UpdateUserAPI(payLoad, payLoad._id)
        if (res.success) {
          toast.success(res.msg)
          closeModal()
          getAllUsers()
        } else {
          toast.error(res.msg)
        }
      } else {
        const res = await AddUserAPI(payLoad)
        if (res.success) {
          toast.success(res.msg)
          closeModal()
          getAllUsers()
        } else {
          toast.error(res.msg)
        }
      }

    } catch (error) {
      toast.error(error.message)
    }
  }

  const settingData = (data) => {
    setRowData({
      _id: data?._id || "",
      password: data.password,
      name: data.name,
      email: data.email,
      role: data.role,
      subscribedPackage: data.subscribedPackage,
    })
  }

  return (
    <DashboardLayout>

      {addModal && <CustomModal title={"Add User"} open={addModal} close={closeModal}>
        <UserForm
          data={rowData}
          handleSubmit={handleSubmit}
          close={closeModal}
        />
      </CustomModal>}

      {editModal && <CustomModal title={"Edit User"} open={editModal} close={closeModal}>
        <UserForm data={rowData} close={closeModal} handleSubmit={handleSubmit} />
      </CustomModal>}

      {viewModal && <CustomModal title={"View User"} open={viewModal} close={closeModal}>
        <UserForm data={rowData} close={closeModal} handleSubmit={handleSubmit} disabled={true} />
      </CustomModal>}



      <DashboardNavbar />
      {false && <MDAlert mt={2} color="dark">
        <MDTypography variant="body2" color="white" fontWeight="medium">
          This is a PRO feature. Click
          <a href="https://www.creative-tim.com/product/material-dashboard-pro-react-nodejs" target="_blank" style={{ color: '#1A73E8' }}>
            &nbsp;here&nbsp;
          </a>
          to see the PRO version
        </MDTypography>
      </MDAlert>}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  User Management
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <CustomTable
                  title=""
                  buttonText="Add User"
                  columnsName={columnsName}
                  rows={tableData}
                  handleAdd={handleAdd}
                  handleView={handleView}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
                {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                /> */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default UserManagement;
