import { FormControl, TextField } from "@mui/material";

// interface CustomInputProps {
//     disabled?: boolean;
//     className?: string;
//     variant?: "outlined" | "filled" | "standard";
//     fullWidth?: boolean;
//     label: string ;
//     name: string;
//     placeholder?: string;
//     formik: any;
//     required?: boolean;
//     type?: "text" | "number" | string;
//     multiline?: boolean;
//     minRows?: number;
//     size?: 'small' | 'medium';
//     inputProps?: any;
//     inputLabelProps?: any;
// }

const CustomInput = (props) => {
    const {
        disabled = false,
        className = "",
        variant = "outlined",
        fullWidth = true,
        label = "",
        name = "",
        placeholder = "",
        formik = null,
        required = false,
        type = "text",
        multiline = false,
        minRows = 3,
        size = "medium",
        inputProps = {},
        inputLabelProps = {},
    } = props
    return (

        <FormControl className={className} fullWidth={fullWidth}>
            <TextField
                InputProps={inputProps}
                InputLabelProps={inputLabelProps}
                size={size}
                minRows={minRows}
                multiline={multiline}
                type={type}
                required={required}
                id={name}
                disabled={disabled}
                label={label}
                name={name}
                variant={variant}
                placeholder={placeholder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[name]}
                error={formik.touched[name] && formik.errors[name] ? true : false}
                helperText={
                    formik.touched[name] &&
                    formik.errors[name] &&
                    formik.errors[name]
                }
            />
        </FormControl>

    )
}

export default CustomInput