import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { toast } from "react-toastify";

// Material-UI components
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";

// Context and services
import { AuthContext } from "context";
import { LoginUserAPI } from "services/user.service";
import { setItemToStorage, getItemFromStorage, logout } from "utils/Constant";

// Images
import logo from "assets/images/logo.png";
import { SendOtpAPI } from "services/otp.service";
import OTPInput from "react-otp-input";
import { VerifyOtpAPI } from "services/otp.service";




const StyledCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  padding: theme.spacing(3),
  width: '100%',
  maxWidth: '400px',
  position: 'fixed', // Changed to 'fixed' to ensure it stays centered regardless of scrolling
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const CircularLogo = styled('div')({
  width: '110px',
  height: '110px',
  borderRadius: '50%',
  overflow: 'hidden',
  margin: '0 auto 20px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: "10px",
});



const MovingBackground = styled('div')({
  position: 'fixed', // Changed from 'absolute' to 'fixed'
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
  zIndex: -1,
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '200%', // Increased to ensure full coverage during animation
    height: '200%',
    top: '-50%',
    left: '-50%',
    background: 'linear-gradient(45deg, #e0f7fa 0%, #eceff1 99%, #eceff1 100%)', // Light blue to greyish white
    animation: 'moveBackground 15s ease infinite',
    backgroundSize: '300% 300%',
  },
  '@keyframes moveBackground': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' },
  },
});


const PasswordReset = () => {

  const location = useLocation()
  const navigate = useNavigate();
  const email = location.state?.email || "";
  const [otp, setOtp] = useState('');

  useEffect(() => {
    if (!email) {
      toast.error('Unauthorized')
      navigate("/");
    }
  }, [])



  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const payLoad = {
        email: email,
        otp: otp
      }
      const res = await VerifyOtpAPI(payLoad);
      if (res.success) {
        navigate('/auth/update-password', {
          state: {
            email: email,
            otp: otp,
          }
        })
        toast.success(res.msg)
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.message || error.msg || error);
    }
  }

  return (
    <BasicLayoutLanding>
      <MovingBackground />
      <StyledCard>
        <CircularLogo>
          <img
            src={logo}
            alt="Company Logo"
            style={{
              width: '150%',  // Adjusted to fill the circular container
              height: '150%', // Adjusted to fill the circular container
              objectFit: 'cover', // Changed from 'contain' to 'cover' to ensure it fills the entire area
              transform: 'translateY(5%)' // Moves the logo down by 10% of its height
            }}
          />
        </CircularLogo>
        <MDTypography variant="h4" fontWeight="medium" color="info" textAlign="center" mb={4}>
          OTP Verification
        </MDTypography>
        <form noValidate onSubmit={handleSubmit}>
          <MDBox mb={3} className="flex justify-center">
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input
                {...props}
                style={{
                  width: '40px',
                  height: '40px',
                  border: '2px solid #ccc',
                  borderRadius: '5px',
                  margin: '0 5px',
                  fontSize: '18px',
                  textAlign: 'center',
                  outline: 'none',
                }}
              />
              }
            />
          </MDBox>

          <MDBox mt={4}>
            <MDButton
              variant="contained"
              color="info"
              fullWidth
              type="submit"
            >
              Verify OTP
            </MDButton>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Sign In{" "}
              <MDTypography
                component={Link}
                to="/"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                here
              </MDTypography>
            </MDTypography>
          </MDBox>
        </form>
      </StyledCard>
    </BasicLayoutLanding>
  );
};

export default PasswordReset;
