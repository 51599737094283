import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// interface CustomCardProps {
//     children: any,
//     className?: string,
// }

const CustomCard = (props) => {
    const { children = null, className = "" } = props
    return (<>
        <Card className={`p-2 ${className}`}>
            <CardContent>
                {children}
            </CardContent>
        </Card>
    </>);
};

export default CustomCard;
