/**
=========================================================
* Auto Call React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Auto Call React components
import MDBox from "components/MDBox";

// Auto Call React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useEffect, useState } from "react";
import { myCallsAPI } from "services/conductedCalls.service";
import { getItemFromStorage } from "utils/Constant";
import { GetCallsAPI } from "services/conductedCalls.service";
import { toast } from "react-toastify";

import { Tooltip, Input, Avatar, TableContainer, Table, TableRow, TableBody, Chip } from '@mui/material';
import { IoEyeOutline } from "react-icons/io5";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { MdDeleteOutline } from "react-icons/md";
import { CiSquarePlus } from "react-icons/ci";
import Pagination from '@mui/material/Pagination';
import CustomTypoGraphy from "components/ReusableComponents/CustomTypography";
import { useMaterialUIController } from "context";
import CustomButton from "components/ReusableComponents/CustomButton";
import CustomModal from "components/ReusableComponents/CustomModal";
import CustomCard from "components/ReusableComponents/CustomCard";
import { getInBoundCallsDetailsAPI } from "services/conductedCalls.service";
import MDButton from "components/MDButton";
import * as XLSX from 'xlsx';


function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const myRole = getItemFromStorage("data")?.role;
  const { subscribedPackage } = getItemFromStorage("data") || "";

  const PackageDetails = {
    Starter: 4.44,
    Business: 5.22,
    Enterprise: 6.33,
    "": 1
  }

  const [data, setData] = useState([])
  const [boxesInfo, setBoxesInfo] = useState({
    averageCallLength: 0,
    totalCallsToday: 0,
    totalCallsThisWeek: 0,
    totalCallsThisMonth: 0,
    monthlyCost: 0,
  })
  const [columnsName, setColumnsName] = useState([
    { label: "Created At", dataField: "created_at" },
    { label: "Direction", dataField: "inbound" },
    { label: "From", dataField: "from" },
    { label: "To", dataField: "to" },
    { label: "Recording", dataField: "recording_url" },
    { label: "Call Length", dataField: "call_length" },
    { label: "Costs", dataField: "price" },
    { label: "Status", dataField: "queue_status" },
    { label: "Transcript", dataField: "transcripts" },
    { label: "Summary", dataField: "summary" },
    { label: "Variables", dataField: "variables" },
    { label: "Analysis", dataField: "analysis" },
    { label: "Call Id", dataField: "c_id" },
  ]);


  useEffect(() => {
    fetchCallsData()
    updatingColumnsName()
  }, [])

  const fetchCallsData = async () => {
    try {
      let res = null

      if (myRole === 'User') {
        res = await myCallsAPI()
      } else {
        res = await GetCallsAPI()
      }

      if (res.success) {
        setData(res.data);
        setBoxesInfo(res.dashboardDetails)
      } else {
        throw new Error(res.msg)
      }
    } catch (error) {
      toast.error(error.msg)
    }
  }

  const updatingColumnsName = () => {
    if (myRole !== "User") {
      setColumnsName([
        { label: "Created At", dataField: "created_at" },
        { label: "Direction", dataField: "inbound" },
        { label: "From", dataField: "from" },
        { label: "To", dataField: "to" },
        { label: "Recording", dataField: "recording_url" },
        { label: "Call Length", dataField: "call_length" },
        { label: "Original Costs", dataField: "price" },
        { label: "Costs", dataField: "price" },
        { label: "Status", dataField: "queue_status" },
        { label: "Transcript", dataField: "transcripts" },
        { label: "Summary", dataField: "summary" },
        { label: "Variables", dataField: "variables" },
        { label: "Analysis", dataField: "analysis" },
        { label: "Call Id", dataField: "c_id" },
      ]);

    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Average Call Length"
                count={boxesInfo.averageCallLength}
              // percentage={{
              //   color: "success",
              //   amount: "+55%",
              //   label: "than lask week",
              // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Total Calls Today"
                count={boxesInfo.totalCallsToday}
              // percentage={{
              //   color: "success",
              //   amount: "+3%",
              //   label: "than last month",
              // }}
              />
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Total Calls Week"
                count={boxesInfo.totalCallsThisWeek}
              // percentage={{
              //   color: "success",
              //   amount: "+1%",
              //   label: "than yesterday",
              // }}
              />
            </MDBox>
          </Grid> */}
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Monthly Cost"
                count={"$ " + parseFloat(boxesInfo.monthlyCost * PackageDetails[subscribedPackage]).toFixed(3) || 0}
              // percentage={{
              //   color: "success",
              //   amount: "+1%",
              //   label: "than yesterday",
              // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Total Calls Month"
                count={boxesInfo.totalCallsThisMonth}
              // percentage={{
              //   color: "success",
              //   amount: "",
              //   label: "Just updated",
              // }}
              />
            </MDBox>
          </Grid>
        </Grid>
        {false && <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>}
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              {/* <Projects /> */}
              <CustomTable
                data={data}
                columnsName={columnsName}
              />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid> */}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;


const CustomTable = ({ data, columnsName }) => {

  const { subscribedPackage } = getItemFromStorage("data") || "";

  const PackageDetails = {
    Starter: 4.44,
    Business: 5.22,
    Enterprise: 6.33,
    "": 1
  }

  const [viewModal, setViewModal] = useState({
    view: false,
    title: "",
    data: {}
  });


  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  // Search state
  const [searchQuery, setSearchQuery] = useState("");

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Filtered and paginated items based on search query
  const filteredRows = data.filter((item) => {
    // Filter by searchQuery
    return Object.values(item).some(value =>
      (typeof value === "string" && value.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (typeof value === "number" && value.toString().includes(searchQuery))
    );
  });

  const currentItems = filteredRows.slice(indexOfFirstItem, indexOfLastItem);
  // Handle pagination change
  const handlePageChange = (e, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle search change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page when search query changes
  };

  const [controller] = useMaterialUIController();
  const { darkMode, sidenavColor } = controller;

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

    return `${formattedDate} (${formattedTime})`;
  }

  const convertDecimalToMinutesAndSeconds = (minutes) => {
    const totalSeconds = minutes * 60;

    const wholeMinutes = Math.floor(totalSeconds / 60);
    const seconds = Math.round(totalSeconds % 60);

    return `${wholeMinutes} minute(s) ${seconds} second(s)`;
  }


  const handleButton = async (title, data) => {


    if (title === 'Transcripts') {
      try {
        const res = await getInBoundCallsDetailsAPI(data)
        if (res.success) {
          setViewModal({
            view: true,
            title: title,
            data: res.data,
          })
        } else {
          throw new Error(res.msg)
        }
      } catch (error) {
        toast.error(error.msg)
      }

    } else {
      setViewModal({
        view: true,
        title: title,
        data: data,
      })
    }
  }

  const closeModal = () => {
    setViewModal({
      view: false,
      title: "",
      data: {}
    })
  }


  const updatedCost = (price) => {
    try {
      let updatedPrice = price * PackageDetails[subscribedPackage];
      return updatedPrice > 0 ? parseFloat(updatedPrice).toFixed(3) : 0
    } catch (error) {
      return 0
    }
  }

  const handleDownloadCSV = () => {
    let dataToDownload = data.map(item => ({
      "created_at": formatDate(item.created_at),
      "inbound": item.inbound ? 'Inbound' : "Outbound",
      "from": item.from,
      "to": item.to,
      "recording_url": item.recording_url,
      "call_length": convertDecimalToMinutesAndSeconds(item.call_length),
      "price": updatedCost(item.price),
      "queue_status": item.queue_status,
      "transcripts": item.transcripts,
      "summary": JSON.stringify(item.summary),
      "variables": JSON.stringify(item.variables),
      "analysis": item.analysis,
      "c_id": item.c_id,
    }))

    const headers = [
      ["Created At", "Direction", "From", "To", "Recording URL", "Call Length", "Cost", "Status", "Transcripts", "Summary", "Variables", "Analysis", "Call ID"]
    ];

    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, headers, { origin: "A1" });
    XLSX.utils.sheet_add_json(worksheet, dataToDownload, { origin: "A2", skipHeader: true });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, 'data.xlsx');
  }

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {viewModal.view && <CustomModal title={viewModal.title} open={viewModal.view} close={closeModal}>
        <ModalInfo data={viewModal.data} close={closeModal} title={viewModal.title} />
      </CustomModal>}
      <div className="px-5 pt-6 pb-2.5">
        <div className="max-w-full overflow-x-auto">
          <div className="flex justify-between items-center">
            <CustomTypoGraphy variant="h5">Call Details</CustomTypoGraphy>
            <div className="row flex justify-center items-center">
              <MDBox className="mr-8">
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="button"
                  onClick={handleDownloadCSV}
                >
                  Download
                </MDButton>
              </MDBox>

              <Input
                className="mt-4 mb-2 mr-4 border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500"
                type="search"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search..."
              />
            </div>
          </div>
          <div>
            {currentItems.length === 0 ? (
              <CustomTypoGraphy variant="h4" className="mt-4 mb-4 flex justify-center items-center">No Data Found</CustomTypoGraphy>
            ) : (
              <>
                <Table className="w-full table-auto mt-4">
                  <MDBox component="thead">
                    <TableRow>
                      {columnsName.map((column, index) => (
                        <MDBox
                          component="th"
                          // width={width}
                          py={1.5}
                          px={3}
                          sx={({ palette: { light }, borders: { borderWidth } }) => ({
                            borderBottom: `${borderWidth[1]} solid ${light.main}`,
                          })}
                          key={column.label}
                        >
                          <MDBox position="relative"
                            textAlign={"Start"}
                            color={darkMode ? "white" : "secondary"}
                            opacity={0.7}
                            sx={({ typography: { size, fontWeightBold } }) => ({
                              fontSize: size.xxs,
                              fontWeight: fontWeightBold,
                              textTransform: "uppercase",
                              cursor: "pointer",
                              userSelect: "none",
                            })}
                          >
                            {column?.label}
                          </MDBox>
                        </MDBox>
                      ))}
                    </TableRow>
                  </MDBox>
                  <TableBody>
                    {currentItems.map((item, rowIndex) => (
                      <TableRow
                        key={item?.c_id || item?.created_at}
                      >
                        {/* <TableCellData>
                          {item?.created_at}
                        </TableCellData> */}
                        <TableCellData>
                          {formatDate(item?.created_at)}
                        </TableCellData>
                        <TableCellData>
                          <Chip label={item?.inbound ? "Inbound" : "Outbound"} color={item?.inbound ? "success" : "info"} />
                        </TableCellData>
                        <TableCellData>
                          {item?.from}
                        </TableCellData>
                        <TableCellData>
                          {item?.to}
                        </TableCellData>
                        <TableCellData>
                          <div className='scroll-container mt-4 flex justify-center'>
                            <audio controls>
                              <source src={item?.recording_url} type="audio/wav" />
                            </audio>
                          </div>
                        </TableCellData>
                        <TableCellData>
                          {convertDecimalToMinutesAndSeconds(item?.call_length)}
                        </TableCellData>
                        {columnsName.map(colName => colName.label).includes("Original Costs") && <TableCellData>
                          {item?.price}
                        </TableCellData>}
                        <TableCellData>
                          {updatedCost(item?.price)}
                        </TableCellData>
                        <TableCellData>
                          {item?.queue_status}
                        </TableCellData>
                        <TableCellData>

                          <CustomButton size="small" color="info" variant="outlined" onClick={() => handleButton("Transcripts", item?.c_id)}
                          >
                            View
                          </CustomButton>
                        </TableCellData>

                        <TableCellData>
                          <CustomButton size="small" color="info" variant="outlined" onClick={() => handleButton("Summary", item?.summary)}
                            disabled={!item?.summary}
                          >
                            View
                          </CustomButton>
                        </TableCellData>
                        <TableCellData>
                          <CustomButton size="small" color="info" variant="outlined" onClick={() => handleButton("Variables", item?.variables)}
                            disabled={!item?.variables}
                          >
                            View
                          </CustomButton>
                        </TableCellData>
                        <TableCellData>
                          <CustomButton size="small" color="info" variant="outlined" onClick={() => handleButton("Analysis", item?.analysis)}
                            disabled={!item?.analysis}
                          >
                            View
                          </CustomButton>
                        </TableCellData>
                        <TableCellData>
                          {item?.c_id || item?.call_id || ""}
                        </TableCellData>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>


                {/* Pagination */}
                <div className="flex justify-center mt-4">
                  <Pagination
                    onChange={handlePageChange}
                    count={Math.ceil(filteredRows.length / itemsPerPage)}
                    color={sidenavColor}
                    shape="rounded"
                  />
                </div>
                <div className="flex justify-end mt-2">
                  <MDBox
                    display="inline-block"
                    width="max-content"
                    color="text"
                    sx={{ verticalAlign: "middle" }}
                  > Total Records : {data.length}</MDBox>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </TableContainer >
  );
};

const TableCellData = ({ children }) => (
  <MDBox
    component="td"
    textAlign={"start"}
    py={1.5}
    px={3}
    sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
      fontSize: size.sm,
      borderBottom: true ? "none" : `${borderWidth[1]} solid ${light.main}`,
    })}
  >
    <MDBox
      display="inline-block"
      width="max-content"
      color="text"
      sx={{ verticalAlign: "middle" }}
    >
      {children}
    </MDBox>
  </MDBox>
)



const ModalInfo = (props) => {
  const { data, close, title } = props;

  return (
    <CustomCard>
      <CustomTypoGraphy noWrap={false}>
        {title === "Summary" && data}
        {title === "Variables" && Object.entries(data).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong> {value}
          </li>
        ))}
        {title === "Analysis" && Object.entries(data).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong> {value}
          </li>
        ))}
        {title === "Transcripts" && (
          data?.call_id ?
            data?.transcripts?.length > 0
              ? data?.transcripts.map(item =>
                <div key={item.id} className="mt-2">
                  <span className="font-bold"> {item.user} : </span> {item.text}
                </div>
              )
              : "No Data Found"
            : "No Data Found"
        )}
      </CustomTypoGraphy>
    </CustomCard>
  )
}