import { useRef, useState } from "react";
import { Tooltip, Input, Avatar, TableContainer, Table, TableRow, TableBody, Chip } from '@mui/material';
import { IoEyeOutline } from "react-icons/io5";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { MdDeleteOutline } from "react-icons/md";
import { CiSquarePlus } from "react-icons/ci";
import Pagination from '@mui/material/Pagination';
import CustomTypoGraphy from "./CustomTypography";
import CustomButton from "./CustomButton";
import MDBox from "components/MDBox";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import { useMaterialUIController } from "context";

// interface CustomTableProps {
//     title?: string;
//     buttonText?: string;
//     handleAdd?: () => void;
//     handleView?: (data) => void;
//     handleEdit?: (data) => void;
//     handleDelete?: (data) => void;
//     handleAnotherButton?: (data) => void;
//     columnsName;
//     rows;
// }

const CustomTable = (props) => {
    const {
        title = "",
        buttonText = "",
        handleAdd = () => null,
        handleView = () => null,
        handleEdit = () => null,
        handleDelete = () => null,
        handleAnotherButton = () => null,
        columnsName = [],
        rows = [],
        search = true
    } = props;
    const tbl = useRef(null);
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    // Search state
    const [searchQuery, setSearchQuery] = useState("");

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Filtered and paginated items based on search query
    const filteredRows = rows.filter((item) => {
        // Filter by searchQuery
        return Object.values(item).some(value =>
            (typeof value === "string" && value.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (typeof value === "number" && value.toString().includes(searchQuery))
        );
    });

    const currentItems = filteredRows.slice(indexOfFirstItem, indexOfLastItem);

    // Handle pagination change
    const handlePageChange = (e, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle search change
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to first page when search query changes
    };

    const [controller] = useMaterialUIController();
    const { darkMode, sidenavColor } = controller;

    return (
        <TableContainer sx={{ boxShadow: "none" }}>
            {/* <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1"> */}
            <div className="px-5 pt-6 pb-2.5">
                <div className="max-w-full overflow-x-auto">
                    <div className="flex justify-between items-center">
                        <CustomTypoGraphy variant="h5">{title}</CustomTypoGraphy>
                        {(buttonText || search) && <div className="row">

                            <Input
                                className="mt-4 mb-2 mr-4 border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500"
                                type="search"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                placeholder="Search..."
                            />
                            {buttonText && <CustomButton color="info" size="medium" onClick={handleAdd}><CiSquarePlus size="22" /> &nbsp; {buttonText}</CustomButton>}
                        </div>}
                    </div>
                    <div>
                        {currentItems.length === 0 ? (
                            <CustomTypoGraphy variant="h4" className="mt-4 mb-4 flex justify-center items-center">No Data Found</CustomTypoGraphy>
                        ) : (
                            <>
                                {/* Table */}
                                <Table ref={tbl} className="w-full table-auto mt-4">
                                    <MDBox component="thead">
                                        {/* <tr className="bg-gray-2 text-left dark:bg-meta-4"> */}
                                        <TableRow>
                                            {columnsName.map((column, index) => (
                                                <MDBox
                                                    component="th"
                                                    // width={width}
                                                    py={1.5}
                                                    px={3}
                                                    sx={({ palette: { light }, borders: { borderWidth } }) => ({
                                                        borderBottom: `${borderWidth[1]} solid ${light.main}`,
                                                    })}
                                                >
                                                    <MDBox position="relative"
                                                        textAlign={"Start"}
                                                        color={darkMode ? "white" : "secondary"}
                                                        opacity={0.7}
                                                        sx={({ typography: { size, fontWeightBold } }) => ({
                                                            fontSize: size.xxs,
                                                            fontWeight: fontWeightBold,
                                                            textTransform: "uppercase",
                                                            cursor: "pointer",
                                                            userSelect: "none",
                                                        })}
                                                    >
                                                        {column?.label}
                                                    </MDBox>
                                                </MDBox>
                                            ))}
                                        </TableRow>
                                    </MDBox>
                                    <TableBody>
                                        {currentItems.map((item, rowIndex) => (
                                            <TableRow key={rowIndex}>
                                                {columnsName.map((column, cellIndex) => (
                                                    <MDBox
                                                        component="td"
                                                        textAlign={"start"}
                                                        py={1.5}
                                                        px={3}
                                                        sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
                                                            fontSize: size.sm,
                                                            borderBottom: true ? "none" : `${borderWidth[1]} solid ${light.main}`,
                                                        })}
                                                        // className="border-b border-[#eee] py-5 px-2 dark:border-strokedark"
                                                        key={cellIndex}>
                                                        {column.dataField === "action" ? (
                                                            <div className="flex justify-around items-center">
                                                                {column?.anotherButton && column?.anotherButtonDetails?.access && (<Tooltip onClick={() => handleAnotherButton(item)} title={column?.anotherButtonDetails.toolTipText} arrow>
                                                                    <div>
                                                                        {column?.anotherButtonDetails.icon}
                                                                    </div>
                                                                </Tooltip>)
                                                                }

                                                                <Tooltip title={"View"} arrow>
                                                                    <div>
                                                                        <IoEyeOutline color="#3c50e0" size="22" onClick={() => handleView(item)} className="cursor-pointer" />
                                                                    </div>
                                                                </Tooltip>

                                                                {column?.access?.edit &&
                                                                    <Tooltip title={"Edit"} arrow>
                                                                        <div>
                                                                            <HiOutlinePencilAlt color="#3c50e0" size="22" onClick={() => handleEdit(item)} className="cursor-pointer" />
                                                                        </div>
                                                                    </Tooltip>
                                                                }

                                                                {column?.access?.delete &&
                                                                    <Tooltip title={"Delete"} arrow>
                                                                        <div>
                                                                            <MdDeleteOutline color="#FF0000" size="22" onClick={() => handleDelete(item)} className="cursor-pointer" />
                                                                        </div>
                                                                    </Tooltip>
                                                                }

                                                            </div>
                                                        ) : column?.avatar ?
                                                            <Avatar alt={item[column?.alt] || "Image not found"} src={imageBaseUrl + item[column?.dataField]} sx={{ width: "32", height: "32" }} />
                                                            : (
                                                                <MDBox
                                                                    display="inline-block"
                                                                    width="max-content"
                                                                    color="text"
                                                                    sx={{ verticalAlign: "middle" }}
                                                                >
                                                                    {column?.date ?
                                                                        item[column?.dataField]?.split('T')[0]
                                                                        :
                                                                        column?.split ?
                                                                            item[column?.dataField]?.split(column?.splitDetials?.splitOn)[column?.splitDetials?.showSplitIndex]
                                                                            :
                                                                            column?.object ?
                                                                                item[column?.dataField][column?.objectField]
                                                                                :
                                                                                column?.highlight ?
                                                                                    <Chip label={item[column?.dataField] ? "Success" : "Failed"} color={item[column?.dataField] ? "success" : "error"} />
                                                                                    :
                                                                                    item[column?.dataField]
                                                                    }
                                                                </MDBox>
                                                            )}
                                                    </MDBox>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>


                                {/* Pagination */}
                                <div className="flex justify-center mt-4">
                                    <Pagination
                                        onChange={handlePageChange}
                                        count={Math.ceil(filteredRows.length / itemsPerPage)}
                                        color={sidenavColor}
                                        shape="rounded"
                                    />
                                </div>
                                <div className="flex justify-end mt-2">
                                    <MDBox
                                        display="inline-block"
                                        width="max-content"
                                        color="text"
                                        sx={{ verticalAlign: "middle" }}
                                    > Total Records : {rows.length}</MDBox>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </TableContainer >
    );
};

export default CustomTable;
