import { Card, Grid } from '@mui/material'
import axios from 'axios'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import CustomButton from 'components/ReusableComponents/CustomButton'
import CustomTable from 'components/ReusableComponents/CustomTable'
import Footer from 'examples/Footer'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { hideLoaderAPI } from 'utils/Constant'
import { showLoaderAPI } from 'utils/Constant'
import * as XLSX from 'xlsx';
import { MakeBlandCallsAPI } from '../../services/conductedCalls.service'
import { getItemFromStorage } from 'utils/Constant'
import CustomModal from 'components/ReusableComponents/CustomModal'
import ConductCallsForm from './ConductCallsForm'
import { GetMyAudiosAPI } from 'services/audio.service'

const ConductCalls = () => {
    const myId = getItemFromStorage("data")?._id
    const backendBaseUrl = process.env.REACT_APP_API_URL;
    const excelUrl = process.env.REACT_APP_API_URL + "sample.xlsx";


    const [allCalls, setAllCalls] = useState([])

    const [columnsName, setColumnsName] = useState([
        { label: "Phone Number", dataField: "phoneNumber" },
    ])

    const [fileInfo, setFileInfo] = useState({
        title: "",
        file: {},
        data: {}
    })

    const [data, setData] = useState([])
    const [editModal, setEditModal] = useState(false)
    const [viewModal, setViewModal] = useState(false)
    const [rowData, setRowData] = useState({});
    const [isHideBtn, setIsHideBtn] = useState(true)

    useEffect(() => {
        getMyAudios()
    }, [])

    const getMyAudios = async () => {
        try {

            const res = await GetMyAudiosAPI()
            if (res.success) {
                setAllCalls(res.data)
            } else {
                toast.error(res.msg)
            }

        } catch (error) {
            toast.error(error.message || error.msg || error)
        }
    }



    const handleFileChange = (event, title, item) => {
        try {
            setFileInfo({ title: "", file: {}, data: {} });
            setIsHideBtn(true);
            setColumnsName([
                { label: "Phone Number", dataField: "phoneNumber" },
            ]);
            setData([]);

            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();

                reader.onload = (evt) => {
                    const arrayBuffer = evt.target.result;
                    const workbook = XLSX.read(arrayBuffer, { type: 'array' });

                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                    if (jsonData.length) {
                        const rowsWithData = jsonData.slice(1).filter(row => row[1]);
                        const newArr = rowsWithData.filter(row => row[0].toString().startsWith('+')) // Filter rows with phone numbers
                            .map((row, index) => ({
                                index: index + 1, // Adjust index to reflect the original row number
                                phoneNumber: row[0] || '', // Ensure phoneNumber is not undefined
                                column2: row[1] || '',
                                column3: row[2] || '',
                                column4: row[3] || '',
                                column5: row[4] || '',
                                column6: row[5] || '',
                                column7: row[6] || '',
                                column8: row[7] || '',
                                column9: row[8] || '',
                                column10: row[9] || '',
                                status: null,
                                callId: null,
                                createdBy: myId,
                                data: JSON.stringify({}),
                            }));

                        if (newArr.length === 0) {
                            toast.error("No valid rows with phone numbers were found.");
                        } else if (newArr.length !== rowsWithData.length) {
                            toast.warning("Some rows were excluded due to missing or invalid phone numbers.");
                        }
                        let remainingColumns = []
                        const cleanedData = newArr.map(item => {
                            const newItem = { ...item }; // Create a shallow copy of the object

                            // List of columns to check
                            const columnsToCheck = [
                                "column2", "column3", "column4", "column5",
                                "column6", "column7", "column8", "column9",
                                "column10"
                            ];

                            // Iterate through columns and delete those with empty values
                            columnsToCheck.forEach(column => {
                                if (newItem[column] === "") {
                                    delete newItem[column];
                                }
                            });

                            remainingColumns = columnsToCheck.filter(column => column in newItem);

                            return newItem;
                        });


                        let columnName = [{ label: "Phone Number", dataField: "phoneNumber" }]
                        for (let index = 0; index < remainingColumns.length; index++) {
                            columnName.push({ label: remainingColumns[index], dataField: remainingColumns[index] })
                        }
                        setColumnsName(columnName)
                        setFileInfo({ title, file, data: item });
                        setData(cleanedData);
                    }
                };

                reader.readAsArrayBuffer(file);
            }
        } catch (error) {
            toast.error(error?.message || error?.msg || "An error occurred");
            setFileInfo({ title: "", file: {}, data: {} });
            setIsHideBtn(true);
            setColumnsName([
                { label: "Phone Number", dataField: "phoneNumber" },
            ]);
            setData([]);
        }
    };

    const handleAdd = async () => {
        showLoaderAPI()

        let payLoad = {
            data: data,
            audioId: fileInfo.data._id
        }
        try {
            const res = await MakeBlandCallsAPI(payLoad)
            if (res.success) {
                toast.success(res.msg)
                setData(res.data);
            } else {
                throw new Error(res.msg)
            }
        } catch (error) {
            toast.error(error.msg)
        }

        setIsHideBtn(false)
        let columnName = JSON.parse(JSON.stringify(columnsName));
        columnName.push({ label: "Status", dataField: "status", highlight: true })
        setColumnsName(columnName)
        hideLoaderAPI()
    };

    const handleView = (payLoad) => {
        settingData(payLoad)
        setViewModal(true);
    }

    const handleEdit = (payLoad) => {
        settingData(payLoad)
        setEditModal(true);
    }

    const closeModal = () => {
        setEditModal(false)
        setViewModal(false)
    }

    const handleSubmit = async (payLoad) => {
        try {
            const array = JSON.parse(JSON.stringify(data));
            const index = array.findIndex(item => item.index == payLoad.index);
            if (index !== -1) {
                array.splice(index, 1, payLoad);
            };
            setData(array);
            closeModal()
        } catch (error) {
            toast.error(error || error.msg || error.message)
        }
    }

    const handleDelete = async (payLoad) => {
        try {
            const array = data.filter(item => item.index !== payLoad.index)
            setData(array);
        } catch (error) {
            toast.error(error.message)
        }
    }

    const settingData = (payLoad) => {
        setRowData(payLoad)
    }

    const handleDownloadSample = () => {
        window.location.href = excelUrl;
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={3} mb={3} className="flex justify-end">
                <CustomButton
                    size="small"
                    color="success"
                    className="mt-4 mb-4"
                    onClick={() => handleDownloadSample()}
                >
                    Download Sample
                </CustomButton>
            </MDBox>
            <MDBox mb={3}>
                <Grid container spacing={3} justifyContent="around">
                    {allCalls.map(item => (
                        <Grid key={item._id} item xs={12} lg={4}>
                            <Card className={`h-100`} style={{ background: fileInfo.title === item.title ? "cornflowerblue" : "" }}>
                                <MDBox p={2}>
                                    <MDTypography variant="h6">{item.title}</MDTypography>
                                    <div className='scroll-container mt-4 flex justify-center'>
                                        <audio controls>
                                            <source src={backendBaseUrl + item.audio} type="audio/wav" />
                                        </audio>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <input
                                            type="file"
                                            accept=".xlsx, .xls"
                                            id={`file-upload-${item.title}`}
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleFileChange(e, item.title, item)}
                                        />
                                        <CustomButton
                                            size="small"
                                            color="info"
                                            onClick={() => document.getElementById(`file-upload-${item.title}`).click()}
                                        >
                                            {item.buttonText}
                                        </CustomButton>
                                    </div>

                                </MDBox>
                            </Card>
                        </Grid>
                    ))}

                </Grid>

                {data.length > 0 && <Grid container className='mt-4'>
                    <Grid item xs={12} lg={12}>
                        <CustomTable
                            title={fileInfo.title}
                            buttonText={isHideBtn ? "Make Calls" : ""}
                            columnsName={columnsName}
                            rows={data}
                            handleAdd={handleAdd}
                            handleView={handleView}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                        />
                    </Grid>
                </Grid>
                }
            </MDBox>

            <Footer />

            {editModal && <CustomModal title={"Edit Detail"} open={editModal} close={closeModal}>
                <ConductCallsForm data={rowData} close={closeModal} handleSubmit={handleSubmit} />
            </CustomModal>}

            {viewModal && <CustomModal title={"View Detail"} open={viewModal} close={closeModal}>
                <ConductCallsForm data={rowData} close={closeModal} handleSubmit={handleSubmit} disabled={true} />
            </CustomModal>}


        </DashboardLayout>
    )
}

export default ConductCalls