import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import CustomInput from 'components/ReusableComponents/CustomInput';
import CustomButton from 'components/ReusableComponents/CustomButton';
import CustomAutoComplete from 'components/ReusableComponents/CustomAutoComplete';
import { GetUsersAPI } from 'services/user.service';
import MDTypography from 'components/MDTypography';
import CustomCheckBox from 'components/ReusableComponents/CustomCheckBox';
import { TextField, Chip, Box, Stack, FormControl, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomTypoGraphy from 'components/ReusableComponents/CustomTypography';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '10px',
  },
  '& .MuiInputBase-input': {
    display: 'flex',
    flexWrap: 'wrap',
    // padding: '0',
  },
}));


const AudioManagementForm = (props) => {
  const {
    data = null,
    handleSubmit = () => null,
    close = () => null,
    disabled = false
  } = props

  const [chips, setChips] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [users, setUsers] = useState([])
  const [analysisSchema, setAnalysisSchema] = useState({
    id: 0,
    key: "",
    value: "",
  })
  const [pronunciationGuideData, setPronunciationGuideData] = useState({
    id: 0,
    word: "",
    pronunciation: "",
    case_sensitive: false,
    spaced: false
  });


  const formik = useFormik({
    initialValues: data,
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Required Field!"),
      buttonText: Yup.string(),
      audioFor: Yup.string().required("Required Field!"),

      pathwayId: Yup.string().required("Required Field!"),
      voice: Yup.string().required("Required Field!"),
      interruptionThreshold: Yup.number(),
      model: Yup.string().required("Required Field!"),
      language: Yup.string().required("Required Field!"),
      timezone: Yup.string().required("Required Field!"),
      voiceMailMessage: Yup.string(),
      voiceMailSms: Yup.string(),
      voiceMailAction: Yup.string().required("Required Field!"),
      maxDuration: Yup.number(),
      from: Yup.string().required("Required Field!"),
      webhook: Yup.string().required("Required Field!"),
      endpoint: Yup.string().required("Required Field!"),
      encryptedKey: Yup.string().required("Required Field!"),
      keywords: Yup.array(),
      pronunciationGuide: Yup.array(),
      analysisSchema: Yup.array(),
      waitingForGreeting: Yup.boolean(),
      blockInterruptions: Yup.boolean(),
      record: Yup.boolean(),

    }),
    onSubmit: (values) => {

      //if (!values.audio?.name) {
       // toast.error('Please upload a valid audio file to proceed')
       // return
      //}

      if (!values.from.startsWith('+')) {
        toast.error('From number must starts with +')
        return
      }

      handleSubmit(values);
    }
  })


  useEffect(() => {
    getAllUsers()
  }, [])

  useEffect(() => {
    formik.setFieldValue("keywords", chips)
  }, [chips])

  const getAllUsers = async () => {
    try {

      const res = await GetUsersAPI()
      if (res.success) {
        setUsers(res.data)
      } else {
        toast.error(res.msg)
      }

    } catch (error) {
      toast.error(error.message || error.msg || error)
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      formik.setFieldValue("audio", file)
    }
  }

  const handleButtonClick = () => {
    document.getElementById('audio-upload').click();
  };

  const handleAddChip = () => {
    if (inputValue && !chips.includes(inputValue)) {
      setChips([...chips, inputValue]);
      setInputValue('');
    }
  };

  const handleDeleteChip = (chipToDelete) => {
    setChips(chips.filter(chip => chip !== chipToDelete));
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddChip();
      event.preventDefault();
    }
  };

  const handleBlur = () => {
    handleAddChip();
  };

  const handleAddPronunciationGuide = () => {
    setPronunciationGuideData({
      id: new Date().getTime(),
      word: "",
      pronunciation: "",
      case_sensitive: false,
      spaced: false
    })
  }

  const savingPronunciation = () => {
    if (!pronunciationGuideData.word) {
      toast.error("Please enter word to continue");
      return
    }

    if (!pronunciationGuideData.pronunciation) {
      toast.error("Please enter pronunciation to continue");
      return
    }

    const payLoad = JSON.parse(JSON.stringify(formik.values.pronunciationGuide))
    payLoad.push(pronunciationGuideData)
    formik.setFieldValue("pronunciationGuide", payLoad)
    setPronunciationGuideData({
      id: 0,
      word: "",
      pronunciation: "",
      case_sensitive: false,
      spaced: false
    })

  }

  const removePronunciation = (item) => {
    const payLoad = formik.values.pronunciationGuide.filter(data => data.id !== item.id)
    formik.setFieldValue("pronunciationGuide", payLoad)
  }


  const handleAddAnalysis = () => {
    setAnalysisSchema({
      id: new Date().getTime(),
      key: "",
      value: "",
    })
  }

  const savingAnalysis = () => {
    if (!analysisSchema.key.trim()) {
      toast.error("Please enter question to continue");
      return
    }

    if (!analysisSchema.value.trim()) {
      toast.error("Please enter answer to continue");
      return
    }

    const payLoad = JSON.parse(JSON.stringify(formik.values.analysisSchema))
    payLoad.push(analysisSchema)
    formik.setFieldValue("analysisSchema", payLoad)
    setAnalysisSchema({
      id: 0,
      key: "",
      value: "",
    })

  }

  const removeAnalysis = (item) => {
    const payLoad = formik.values.analysisSchema.filter(data => data.id !== item.id)
    formik.setFieldValue("analysisSchema", payLoad)
  }




  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className="grid grid-cols-12 gap-6 px-4">

          <CustomInput
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            name="title"
            label="Title"
            placeholder="Enter Title Here..."
          />

          <CustomInput
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            name="buttonText"
            label="Button Text"
            placeholder="Enter Button Text Here..."
          />

          <CustomAutoComplete
            disabled={disabled}
            formik={formik}
            label="Select User"
            name="audioFor"
            options={users}
            labelsList={['name', "email"]}
            savedLabel='_id'
            required={true}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            placeholder="Select User"
            disableClearable={true}
          />

          <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 ">
            {formik.values.audio?.name && <MDTypography variant="h6">{formik.values.audio?.name} uploaded</MDTypography>}

            <input
              type="file"
              accept=".wav"
              id="audio-upload"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <CustomButton
              size="small"
              color="info"
              onClick={handleButtonClick}
            >
              Upload Audio
            </CustomButton>
          </div>

          <CustomInput
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            name="pathwayId"
            label="Pathway ID"
            placeholder="075cb660-5280-4966-8768-63a47cde6555"
          />

          <CustomInput
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            name="voice"
            label="Voice"
            placeholder="1fe9055c-354c-4e45-ae4d-172e063ae7b3"
          />


          <CustomInput
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            name="interruptionThreshold"
            label="Interruption Threshold"
            type="number"
            placeholder="100"
          />

          <CustomInput
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            name="model"
            label="Model"
            placeholder="enhanced"
          />

          <CustomInput
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            name="language"
            label="Language"
            placeholder="en-AU"
          />

          <CustomInput
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            name="timezone"
            label="Timezone"
            placeholder="America/Los_Angeles"
          />


          <CustomAutoComplete
            disabled={disabled}
            formik={formik}
            label="Select Voice Mail Action"
            name="voiceMailAction"
            options={[
              { key: "Ignore", value: "ignore" },
              { key: "Hangup", value: "hangup" },
              { key: "Leave Message", value: "leave_message" },
              { key: "Leave Sms", value: "leave_sms" },
              { key: "Leave Message and Sms", value: "leave_message_and_sms" },
            ]}
            labelsList={['key']}
            savedLabel='value'
            required={true}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            placeholder="Select Voice Mail Action"
            disableClearable={true}
          />


          <CustomInput
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            name="voiceMailMessage"
            label="Voice Mail Message"
            placeholder="Enter Voice Mail Message Here..."
          />

          <CustomInput
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            name="voiceMailSms"
            label="Voice Mail Sms"
            placeholder="Enter Voice Mail Sms Here..."
          />

          <CustomInput
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            name="maxDuration"
            label="Max Duration"
            type="number"
            placeholder="123"
          />

          <CustomInput
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            name="from"
            label="From"
            placeholder="+61482071114"
          />

          <CustomInput
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            name="webhook"
            label="Webhook"
            placeholder="https://hook.eu2.make.com/wdd5catq4lmfenlf73s4pppds7sicw2u"
          />

          <CustomInput
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            name="endpoint"
            label="Endpoint"
            placeholder="https://api.bland.ai/v1/calls"
          />

          <CustomInput
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
            name="encryptedKey"
            label="Encrypted Key"
            placeholder="********-****-****-****-************"
          />

          <CustomTextField
            variant="outlined"
            label="Keywords"
            className='col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 '
            placeholder="Type and press Enter to add"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            InputProps={{
              startAdornment: (
                <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', alignItems: 'center' }}>
                  {chips.map((chip, index) => (
                    <Chip
                      key={index}
                      label={chip}
                      onDelete={() => handleDeleteChip(chip)}
                      sx={{ margin: '2px' }}
                    />
                  ))}
                </Stack>
              ),
              endAdornment: null,
            }}
            sx={{ width: '100%' }}
          />

          <CustomCheckBox
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4 xl:col-span-4 '
            name="waitingForGreeting"
            label="Waiting For Greeting"
            placeholder="Waiting For Greeting"
          />

          <CustomCheckBox
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4 xl:col-span-4 '
            name="blockInterruptions"
            label="Block Interruptions"
            placeholder="Block Interruptions"
          />

          <CustomCheckBox
            disabled={disabled}
            formik={formik}
            className='col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-4 xl:col-span-4 '
            name="record"
            label="Record"
            placeholder="Record"
          />

          <div className='col-span-12 mt-2 flex justify-between'>
            <CustomTypoGraphy className="col-span-3">
              Analysis Schema
            </CustomTypoGraphy>

            <CustomButton
              color="success"
              size='small'
              className='col-span-3'
              type='button'
              onClick={() => handleAddAnalysis()}
            >
              Add Analysis Schema
            </CustomButton>
          </div>

          {analysisSchema.id !== 0 && <div className='col-span-12'>
            <div className='grid grid-cols-12 gap-6'>

              <FormControl
                className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
                fullWidth={true}>
                <TextField
                  size="medium"
                  required={true}
                  label="Add Question For Schema"
                  name="key"
                  variant="outlined"
                  placeholder="Question For Analysis Schema"
                  onChange={(e) => setAnalysisSchema(prevState => { return { ...prevState, key: e.target.value } })}
                  value={analysisSchema.key}
                />
              </FormControl>

              <FormControl
                className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
                fullWidth={true}
              >
                <TextField
                  size="medium"
                  className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 '
                  required={true}
                  label="Add Answer For Schema"
                  name="value"
                  variant="outlined"
                  placeholder="Answer For Analysis Schema"
                  onChange={(e) => setAnalysisSchema(prevState => { return { ...prevState, value: e.target.value } })}
                  value={analysisSchema.value}
                />
              </FormControl>
            </div>
            <div className='flex justify-end col-span-12 mt-4'>
              <CustomButton
                color="primary"
                size='small'
                className='col-span-3 '
                type='button'
                onClick={savingAnalysis}
              >
                Add
              </CustomButton>

            </div>
          </div>}

          <ol className='col-span-12'>

            {formik.values.analysisSchema.map((item) => (
              <li key={item.id}>
                <div className='grid grid-cols-12 py-4'>
                  <div className='col-span-8'>
                    <CustomTypoGraphy variant="body1">
                      {item.key} : {item.value}
                    </CustomTypoGraphy>
                  </div>
                  <div className='col-span-4 flex justify-end'>
                    <CustomButton
                      color="error"
                      size='small'
                      className='col-span-3  '
                      type='button'
                      onClick={() => removeAnalysis(item)}
                    >
                      Remove
                    </CustomButton>
                  </div>
                </div>
                <hr />
              </li>
            ))
            }
          </ol>

          <div className='col-span-12 mt-2 flex justify-between'>
            <CustomTypoGraphy className="col-span-3">
              Pronunciation Guide
            </CustomTypoGraphy>

            <CustomButton
              color="success"
              size='small'
              className='col-span-3'
              type='button'
              onClick={() => handleAddPronunciationGuide()}
            >
              Add Pronunciation Guide
            </CustomButton>
          </div>
          {pronunciationGuideData.id !== 0 && <div className='col-span-12'>
            <div className='grid grid-cols-12 gap-6'>
              <AudioManagementInput

                label="Word"
                name="word"
                className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 mt-2"
                required={true}
                pronunciationGuideData={pronunciationGuideData}
                setPronunciationGuideData={setPronunciationGuideData}
              />
              <AudioManagementInput
                label="Pronunciation"
                name="pronunciation"
                className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 mt-2"
                required={true}
                pronunciationGuideData={pronunciationGuideData}
                setPronunciationGuideData={setPronunciationGuideData}
              />

              <FormControl
                component="fieldset"
                className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 "
              >
                <FormGroup>
                  <FormControlLabel
                    label="Case Sensitive"
                    control={<Checkbox
                      name="case_sensitive"
                      onChange={(e) => setPronunciationGuideData(prevState => ({ ...prevState, ["case_sensitive"]: e.target.checked }))}
                      checked={pronunciationGuideData["case_sensitive"]}
                    />}
                  />
                </FormGroup>
              </FormControl>

              <FormControl
                component="fieldset"
                className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 "
              >
                <FormGroup>
                  <FormControlLabel
                    label="Spaced"
                    control={<Checkbox
                      name="spaced"
                      onChange={(e) => setPronunciationGuideData(prevState => ({ ...prevState, ["spaced"]: e.target.checked }))}
                      checked={pronunciationGuideData["spaced"]}
                    />}
                  />
                </FormGroup>
              </FormControl>
            </div>
            <div className='flex justify-end col-span-12'>
              <CustomButton
                color="primary"
                size='small'
                className='col-span-3 '
                type='button'
                onClick={savingPronunciation}
              >
                Add
              </CustomButton>

            </div>
          </div>}



          <ol className='col-span-12'>

            {formik.values.pronunciationGuide.map((item) => (
              <li key={item.id}>
                <div className='grid grid-cols-12 py-4'>
                  <div className='col-span-8'>
                    <CustomTypoGraphy>
                      Word : {item.word}
                    </CustomTypoGraphy>
                    <CustomTypoGraphy>
                      Pronunciation : {item.pronunciation}
                    </CustomTypoGraphy>
                    <CustomTypoGraphy>
                      Case Sensitive : {item.case_sensitive ? "Yes" : "No"}
                    </CustomTypoGraphy>
                    <CustomTypoGraphy>
                      Spaced : {item.spaced ? "Yes" : "No"}
                    </CustomTypoGraphy>
                  </div>
                  <div className='col-span-4 flex justify-end'>
                    <CustomButton
                      color="error"
                      size='small'
                      className='col-span-3 '
                      type='button'
                      onClick={() => removePronunciation(item)}
                    >
                      Remove
                    </CustomButton>
                  </div>
                </div>
                <hr />
              </li>
            ))
            }
          </ol>


          <CustomButton
            color="primary"
            size='large'
            className='col-span-12'
            type='submit'
          >
            Add Audio
          </CustomButton>
        </div>
      </form >

    </>
  )
}

export default AudioManagementForm

const AudioManagementInput = (props) => {
  const {
    disabled = false,
    className = "",
    variant = "outlined",
    fullWidth = true,
    label = "",
    name = "",
    placeholder = "",
    required = false,
    type = "text",
    multiline = false,
    minRows = 3,
    size = "medium",
    inputProps = {},
    inputLabelProps = {},
    setPronunciationGuideData,
    pronunciationGuideData,
  } = props;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPronunciationGuideData(prevState => ({ ...prevState, [name]: value }))
  }



  return (
    <FormControl className={className} fullWidth={fullWidth}>
      <TextField
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
        size={size}
        minRows={minRows}
        multiline={multiline}
        type={type}
        required={required}
        id={name}
        disabled={disabled}
        label={label}
        name={name}
        variant={variant}
        placeholder={placeholder}
        onChange={handleChange}
        value={pronunciationGuideData[name]}
      // error={required && !pronunciationGuideData[name].trim() && true}
      // helperText={
      //   required && !pronunciationGuideData[name].trim() && "Required Field"
      // }
      />
    </FormControl>

  )
}